import http from "../utils/http"
const baseUrl = '/app/study'

/**
 * 获取帮助列表
 * @params
 * method:Get
 * path:'app/study/helpdocs'
*/

export const getHelp = params => http.get(`${baseUrl}/helpdocs`, {params})

/**
 * 获取帮助详情
 * @params {articleId}
 * method:Get
 * path:'app/study/helpdocs/{articleId}'
*/

export const getHelpDetails = articleId => http.get(`${baseUrl}/helpdocs/${articleId}`)

/**
 * 意见反馈
 * @params {content}
 * method:post
 * path:'app/study/feedback'
*/

export const submitFeedback = content => http.post(`${baseUrl}/feedback`, {content})

/**
 * 获取个人信息
 * @params {member}
 * method:Get
 * path:'app/study/member'
*/

export const getUserInfo = () => http.get(`${baseUrl}/member`)

/**
 * 修改个人信息
 * @params {member}
 * method:put
 * path:'app/study/member'
*/

export const editUserInfo = userInfo => http.put(`${baseUrl}/member`, userInfo)

/**
 * 发送短信验证码
 * @params {mobile}
 * method:POST
 * path:'app/study/sms'
*/

export const senCode = mobile => http.post(`${baseUrl}/sms`, {mobile})

/**
 * 绑定手机号
 * @params {list}
 * method:POST
 * path:'app/study/member/binding/mobile'
*/

export const confirmBinding = list => http.post(`${baseUrl}/member/binding/mobile`, list)

/**
 * 修改密码
 * @params {pwd}
 * method:POST
 * path:'app/study/member/modify/password'
*/

export const editPwd = pwd => http.post(`${baseUrl}/member/modify/password`, pwd)

/**
 * 重置密码发送短信
 * @params
 * method:POST
 * path:'app/study/member/reset/password/sms'
*/

export const pwdCode = () => http.post(`${baseUrl}/member/reset/password/sms`)

/**
 * 重置密码
 * @params
 * method:POST
 * path:'app/study/member/reset/password'
*/

export const resetPwd = list => http.post(`${baseUrl}/member/reset/password`,list)