import Vue from 'vue'
import App from './App.vue'
import less from 'less'
import store from './store'
import router from './router'
import moment from 'moment'
import './assets/aliIcon/iconfont.css'
import './assets/aliIcon/iconfont.js'
import './assets/css/common.less'
import './assets/css/reset.less'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/aliIcon/iconfont.js'
import Hls from "hls.js";
import loading from './components/loading/loading.js'
window.Hls = Hls



import {
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  Breadcrumb,
  BreadcrumbItem,
  Dialog,
  Radio,
  RadioButton,
  RadioGroup,
  Pagination,
  Tabs,
  TabPane,
  Rate,
  Input,
  Collapse,
  CollapseItem,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Message,
  MessageBox,
  Loading,
  Select,
  Option,
  Checkbox,
  Upload,
  Progress,
  Image,
  Tooltip,
  PageHeader,
  Form,
  FormItem,
  DatePicker,
  Cascader
} from 'element-ui';

Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(PageHeader)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Rate)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Checkbox)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Image)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.use(loading)
Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.use(less)
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
