import axios from 'axios'
import router from '@/router'
// import { expire } from "@/utils/expire";
import {
    getToken,
    removeToken
} from "@/utils/getToken"

const service = axios.create({
    withCredentials: false,
    timeout: 10000
})

// service.defaults.headers.token = localStorage.getItem('token') || '';

service.interceptors.request.use(
    async config => {
            config.headers['token'] = await getToken();
            return config
        },
        error => {
            return Promise.reject(error)
        }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        // 过期时间
        // let exp = new Date(localStorage.getItem('exp')).getTime()
        if (res.code === 401) {
            removeToken()
            router.replace({
                name: 'login'
            })
        } else {
            return res
        }
    },
    error => {
        console.log(error)
    }
)

export default service