import http from "../utils/http"
import axios from 'axios'
import { Message } from 'element-ui'
const baseUrl = '/app/study'

/**
 * 查询分类信息列表
 * @params
 * method:get
 * path:'/app/study/categorys'
 */

export const getCategorys = () => http.get(`${baseUrl}/categorys`)

/**
 * 轮播图查询
 * @params
 * method:get
 * path:'/app/study/banner'
 */

export const getBanner = params => http.get(`${baseUrl}/banner`, { params } )

/**
 * 获取系统当前时间
 * @param 
 * method:get
 * path:'/app/study/time'
*/

export const getCurrentTime = () => http.get(`${baseUrl}/time`)

/**
 * 添加用户猜你喜欢
 * @param 
 * method:put
 * path:'/app/member/favorite'
*/

export const addInterst = params => http.put(`${baseUrl}/member/favorite`, params )

/**
 * 添加用户猜你喜欢
 * @param 
 * method:get
 * path:'/app/member/favorite'
*/

export const getInterst = params => http.get(`${baseUrl}/member/favorite`, { params } )


export const upload = params => http.post(`${baseUrl}/file/upload`, params)

/**
 * 获取阿里云OSS上传凭证
 * /app/teacher/org
 */

export const uploadSign = () => http.get(`${baseUrl}/oss/sign`)


/**
 *上传图片到阿里云OSS
 * @param {host,data}
 */
export const aliyunUpload = (host, data) => new Promise((resolve) => {
    axios.post(host, data).then((res) => {
        if(res.status === 200) {
            resolve(res)
            // Message.success({
            //     message:'上传成功'
            // })
        }else {
            Message.success({
                type:'error',
                message:'上传失败'
            })
        }
    })
})
