import Vue from 'vue';
import Vuex from 'vuex';
import { getCategorys, getCurrentTime } from "@/api/index"
import { getUserInfo } from "@/api/personal"
import { Message } from 'element-ui'
import { buildTree } from "@/utils/treeShape"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 是否为私有化部署环境
        currentEnvironment: process.env.VUE_APP_PRIVATE == 'true',
        classification: [],
        classificationOrgin: [],
        personalInfo: JSON.parse(localStorage.getItem('personalInfo')) || {}, //个人信息
        currentTime: null, //系统当前时间
    },
    mutations: {
        SET_CLASSIFICATION(state, val) {
            state.classification = val.length !== 0 ? buildTree(val) : []
            state.classificationOrgin = val;
        },
        SET_PERSONALINFO(state, val) {
            state.personalInfo = val
            localStorage.setItem('personalInfo', JSON.stringify(val))
        },
        CLEAR_PERSONALINFO(state) {
            state.personalInfo = {}
            localStorage.removeItem('personalInfo')
        },
        GET_CURRENTTIME(state, val) {
            state.currentTime = val
        }
    },
    actions: {
        getCategorys(context) {
            getCategorys().then(json => {
                const { code, data, msg } = json;
                if (code === 200) {
                    context.commit('SET_CLASSIFICATION', data);
                } else {
                    Message.error(msg)
                }
            })
        },
        getPersonalInfo(context) {
            getUserInfo().then(json => {
                const { code, data, msg } = json;
                if (code === 200) {
                    context.commit('SET_PERSONALINFO', data);
                } else {
                    Message.error(msg)
                }
            })
        },
        getCurrentTime(context) {
            return new Promise((resolve, reject) => {
                getCurrentTime().then(json => {
                    const { code, time, msg } = json;
                    if (code === 200) {
                        context.commit('GET_CURRENTTIME', time);
                        resolve(time)
                    } else {
                        Message.error(msg);
                        reject(msg)
                    }
                })
            })
        }
    }
})