import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
    routes: [{
        path: '/',
        name: '首页入口',
        component: () => import( /* webpackChunkName: "home" */ '../pages/home/index.vue'),
        redirect: '/home',
        children: [{
            path: '/home',
            name: 'home',
            component: () => import( /* webpackChunkName: "home" */ '../pages/home/home.vue'),
        }, {
            path: '/allCourses',
            name: 'allCourses',
            component: () => import( /* webpackChunkName: "allCourses" */ '../pages/home/allCourses.vue'),
        }, {
            path: '/myStudy',
            name: 'myStudy',
            component: () => import( /* webpackChunkName: "myStudy" */ '../pages/home/myStudy.vue'),
        }, {
            path: '/helpCenter',
            name: 'helpCenter',
            component: () => import( /* webpackChunkName: "helpCenter" */ '../pages/home/helpCenter.vue'),
        }, {
            path: '/courseDetail',
            name: 'courseDetail',
            component: () => import( /* webpackChunkName: "courseDetail" */ '../pages/courseDetail/index.vue'),
        },{
            path: '/courseVideo',
            name: 'courseVideo',
            component: () => import( /* webpackChunkName: "courseVideo" */ '../pages/courseDetail/courseVideo.vue'),
        },{
            path: '/order',
            name: 'order',
            component: () => import( /* webpackChunkName: "order" */ '../pages/payment/order.vue'),
        },{
            path: '/pay',
            name: 'pay',
            component: () => import( /* webpackChunkName: "pay" */ '../pages/payment/pay.vue'),
        },{
            path: '/recruit',
            name: 'recruit',
            component: () => import( /* webpackChunkName: "recruit" */ '../pages/home/recruit/index.vue')
        },{
            path: '/jobDetail',
            name: 'recruit',
            component: () => import( /* webpackChunkName: "jobDetail" */ '../pages/home/recruit/jobDetail.vue')
        },{
            path: '/personalData',
            name: 'recruit',
            component: () => import( /* webpackChunkName: "personalData" */ '../pages/home/recruit/personalData.vue')
        },{
            path: '/companyData',
            name: 'recruit',
            component: () => import( /* webpackChunkName: "companyData" */ '../pages/home/recruit/companyData.vue')
        },{
            path: '/releaseRecruit',
            name: 'recruit',
            component: () => import( /* webpackChunkName: "releaseRecruit" */ '../pages/home/recruit/releaseRecruit.vue')
        },{
            path: '/news',
            name: 'news',
            component: () => import( /* webpackChunkName: "news" */ '../pages/home/news/index.vue')
        },{
            path: '/newsDetail',
            name: 'newsDetail',
            component: () => import( /* webpackChunkName: "newsDetail" */ '../pages/home/news/detail.vue')
        }
    ],
    },{
        path:'/login',
        name:'login',
        component: () => import( /* webpackChunkName: "login" */ '../pages/login/index.vue'),
    },
    {
        path:'/register',
        name:'register',
        component: () => import( /* webpackChunkName: "register" */ '../pages/register/index.vue'),
    }]
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}