<template>
  <div id="app" @contextmenu.prevent>
    <router-view></router-view>
  </div>
</template>

<script>
// import upload from './utils/uploadMethod'
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {
    
  },
  methods: {},
};
</script>

<style lang="less">
#app {
  min-width: 1200px;
}
</style>
