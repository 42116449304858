<template>
  <div class="loading_content_sty" v-if="show">
    <lottie
      :options="defaultOptions"
      :height="100"
      :width="100"
      v-on:animCreated="handleAnimation"
      class="lottie_sty"
    />
  </div>
</template>

<script>
import lottie from "vue-lottie";
import * as animationData from "../../../public/loading.json";
export default {
  components: {
      lottie
  },
  props:{
    show:Boolean
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      defaultAnim: "",
    };
  },
  methods: {
    handleAnimation(anim) {
      this.defaultAnim = anim;
    },
  },
};
</script>

<style lang="less" scoped>
.loading_content_sty {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    .lottie_sty {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
</style>