const tokenKey = 'token'

export const getToken = function () {
  return new Promise((resolve) => {
    resolve(localStorage.getItem(tokenKey))
  })
}

export const removeToken = () => {
  localStorage.removeItem(tokenKey)
}