/**
 * 将一维的扁平数组转换为多层级对象
 * @param  {[type]} list 一维数组，数组中每一个元素需包含categoryId和parentId两个属性 
 * @return {[type]} tree 多层级树状结构
 */
export const buildTree = function (list) {
    let copyedObj = JSON.parse(JSON.stringify(list)) //深拷贝源数据
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent.categoryId === child.parentId
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return parent.parentId == 0 //返回顶层，依据实际情况判断这里的返回值
    })
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(data, id, parentId, children, rootId) {
	id = id || 'id'
	parentId = parentId || 'parentId'
	children = children || 'children'
	rootId = rootId || 0
	//对源数据深度克隆
	const cloneData = JSON.parse(JSON.stringify(data))
	//循环所有项
	const treeData = cloneData.filter(father => {
		let branchArr = cloneData.filter(child => {
			//返回每一项的子级数组
			return father[id] === child[parentId]
		});
		branchArr.length > 0 ? father.children = branchArr : '';
		//返回第一层
		return father[parentId] === rootId;
	});
	return treeData != '' ? treeData : data;
}